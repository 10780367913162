import React, { useState, useEffect } from 'react';
import axios from 'axios-esm';
import WebApp from '@twa-dev/sdk';
// import usePhantomWallet from "./PhantomWallet"; // Исправление пути

function ProfileBlock() {
    const user = WebApp.initDataUnsafe?.user;
    const [userData, setUserData] = useState(null);
    const [callbackUrl, setCallbackUrl] = useState('');
    const [walletAddress, setWalletAddress] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://app.bdsm.money:5000/user/${user.id}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке данных пользователя:', error);
            }
        };

        if (user && user.id) {
            fetchUserData();
        }
    }, [user]);

    const connectWallet = async () => {
        const response = await axios.get(`https://app.bdsm.money:5000/user/${user.id}/get-callback-url`);
        setCallbackUrl(response.data.callbackUrl);
    }

    return (
        <div className='ProfileBlock'>
            <div className="avatar">
                <img src="https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg?size=626&ext=jpg" alt="Avatar" />
            </div>
            <div className="info">
                <h2>{user?.first_name} {user?.last_name}</h2>
                <p>Уровень: {userData?.level}</p>
                <p>Баланс: {Math.floor(userData?.balance)} $FAP</p>
            </div>
            <div>
                {walletAddress ? (
                    <span>{`${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}</span>
                ) : (
                    <button onClick={connectWallet}>Connect Wallet</button>
                )}
            </div>
            <div>
                {callbackUrl ? (
                    <a href={callbackUrl} target='_blank'>Open wallet connection page</a>
                ) : null}
            </div>
        </div>
    );
}

export default ProfileBlock;
