import React, { useState, useEffect } from 'react';
import CardBlock from './CardBlock';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

function CardsWrapper() {
  const [cards, setCards] = useState([]);
  const [userId, setUserId] = useState(WebApp.initDataUnsafe?.user?.id || null);
  const [userCards, setUserCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://app.bdsm.money:5000/cards/`);
        setCards(response.data);
      } catch (e) {
        console.log('Ошибка загрузки карточек:', e);
      }
    };

    const fetchUserCards = async () => {
      try {
        if (userId) {
          const response = await axios.get(`https://app.bdsm.money:5000/user/${userId}`);
          setUserCards(response.data.cards);
        }
      } catch (e) {
        console.log('Ошибка загрузки карточек пользователя:', e);
      }
    };

    fetchData();
    fetchUserCards();
  }, [userId]);

  return (
    <div className='CardsWrapper'>
      <div className="CardsWrapper__container">
        {cards.map((card) => {
          const userCard = userCards.find((uc) => uc.cardId === card._id);
          const level = userCard ? userCard.level : 0;
          const price = userCard ? card.upgradeCosts[level] : card.price;
          const income = userCard ? card.incomePerHour[userCard.level - 1] : 0;

          return (
            <CardBlock
              key={card._id}
              cardId={card._id}
              userId={userId}
              userCardId={userCard?._id}
              image={card.imgUrl}
              price={price}
              isOwned={!!userCard}
              level={level}
              title={card.title}
              description={card.description}
              upgradeCosts={card.upgradeCosts} // Передаем upgradeCosts для CardBlock
              incomePerHour={card.incomePerHour} // Передаем прибыль
            />
          );
        })}
      </div>
    </div>
  );
}

export default CardsWrapper;
