import React, { useEffect } from 'react';
import HomeHeader from '../components/Home/HomeHeader';
import TaskBlock from '../components/Tasks/TaskBlock';
import useTaskStore from '../store/useTaskStore';

function Tasks() {
  const { tasks, fetchTasks, isLoading } = useTaskStore();

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div className="Tasks">
      <HomeHeader />

      <div className="tasks__container">
        <div className="title">
          Задания
        </div>

        <div className="text">
          Выполняйте задания и получайте баллы, которые обменивайте на $BDSM
        </div>

        {isLoading ? (
          <p>Загрузка...</p>
        ) : (
          tasks.map((task) => (
            <TaskBlock key={task._id} task={task} />
          ))
        )}
      </div>
    </div>
  );
}

export default Tasks;
