import React from 'react';
import { BsTelegram, BsBrowserChrome, BsArrowRight, BsCheckCircle } from "react-icons/bs";
import useTaskStore from '../../store/useTaskStore';
import WebApp from '@twa-dev/sdk';

function TaskBlock({ task }) {
  const { checkTask } = useTaskStore();

  const userId = WebApp.initDataUnsafe?.user.id

  return (
    <div className="TaskBlock">
      <div className="left">
        <div className="icon">
          {task.type === "subscribe" || task.type === "join" ? (
            <BsTelegram />
          ) : (
            <BsBrowserChrome />
          )}
        </div>

        <div className="title">
          {task.title}
        </div>
      </div>

      <div className="right">
        <div className="icon">
          <a href={`${task.link}`}><BsArrowRight /></a>
        </div>
        <div className="icon check" onClick={() => checkTask(task._id, userId)}>
          <BsCheckCircle />
        </div>
      </div>
    </div>
  );
}

export default TaskBlock;
