import React, { useEffect, useState } from "react";
import WebApp from "@twa-dev/sdk";
import useUserStore from "../../store/UserStore";

function ProfileBlock() {
    const user = WebApp.initDataUnsafe?.user;
    const { user: userData, fetchUser } = useUserStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (user?.id) {
            fetchUser(user.id);
        }
    }, [user?.id, fetchUser]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="ProfileBlock" style={{ padding: "20px", textAlign: "center" }}>
            <div className="avatar">
                <img
                    src="https://i.pinimg.com/736x/b7/5b/29/b75b29441bbd967deda4365441497221.jpg"
                    alt="Avatar"
                />
            </div>
            <div className="info">
                <h2>
                    {user?.first_name} {user?.last_name}
                </h2>
                {userData ? (
                    <>
                        <p>Уровень: {userData.level}</p>
                        <p>Баланс: {Math.floor(userData.balance)} $FAP</p>

                        {userData.walletAddress ? (
                            <p style={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>🎲 Лотереи отсутствуют 🎲</p>
                        ) : (
                            <button
                                onClick={toggleModal}
                                style={{
                                    marginTop: "20px",
                                    padding: "10px 20px",
                                    backgroundColor: "#591C4B",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontFamily: "Montserrat"
                                }}
                            >
                                Connect wallet
                            </button>
                        )}
                    </>
                ) : (
                    <p>Данные пользователя отсутствуют</p>
                )}
            </div>

            {isModalOpen && !userData?.walletAddress && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            padding: "20px",
                            borderRadius: "10px",
                            width: "300px",
                            textAlign: "center",
                            color: "#000"
                        }}
                    >
                        <h3>Инструкция</h3>
                        <p>1️⃣ Откройте бота в Telegram</p>
                        <p>2️⃣ Введите команду <b>/connect</b></p>
                        <p>3️⃣ Следуйте инструкциям в боте</p>
                        <button
                            onClick={toggleModal}
                            style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                backgroundColor: "#dc3545",
                                color: "#000",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        >
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProfileBlock;
