import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api';
import { showNotification } from '../ToastNotification';

const useTaskStore = create(
  devtools((set, get) => ({
    tasks: [],
    isLoading: false,
    error: null,

    fetchTasks: async () => {
      set({ isLoading: true, error: null });
      try {
        const response = await api.get('/tasks');
        set({ tasks: response.data });
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Ошибка при загрузке задач';
        showNotification(errorMessage, 'error');
        set({ error: errorMessage });
      } finally {
        set({ isLoading: false });
      }
    },

    checkTask: async (taskId, userId) => {
      try {
        const response = await api.post(`/tasks/check`, { taskId, userId });
        showNotification('Задача успешно выполнена!', 'success');

        set((state) => ({
          tasks: state.tasks.map((task) =>
            task._id === taskId ? { ...task, completed: true } : task
          ),
        }));

        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Ошибка при выполнении задачи';
        showNotification(errorMessage, 'error');
        set({ error: errorMessage });
        return { error: errorMessage };
      }
    },
  }))
);

export default useTaskStore;
