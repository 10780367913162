import React, { useState, useEffect } from 'react';
import { BsCoin } from "react-icons/bs";
import axios from 'axios-esm';
import WebApp from '@twa-dev/sdk';

function CardBlock(props) {
  const user = WebApp.initDataUnsafe?.user;
  const [isOwned, setIsOwned] = useState(props.isOwned);
  const [level, setLevel] = useState(props.level);
  const [price, setPrice] = useState(props.price);
  const [nextUpgradeCost, setNextUpgradeCost] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [income, setIncome] = useState(0); // Начальная прибыль установлена в 0

  useEffect(() => {
    setIsOwned(props.isOwned);
    setLevel(props.level);
    setPrice(props.price);

    // Устанавливаем прибыль на основе уровня, с проверкой на наличие данных
    const incomeForLevel = props.incomePerHour && props.incomePerHour[props.level - 1];
    setIncome(incomeForLevel || 0);

    if (props.isOwned && props.level < 10) {
      setNextUpgradeCost(props.upgradeCosts[props.level] || 'Макс. уровень');
    }
  }, [props.isOwned, props.level, props.price, props.upgradeCosts, props.incomePerHour]);

  const getFormattedPrice = (price) => {
    if (price >= 1000000) {
      return `${price / 1000000}М`;
    } else if (price >= 1000) {
      return `${price / 1000}К`;
    }
    return price;
  };

  const handleBuy = async () => {
    try {
      await axios.post(`https://app.bdsm.money:5000/cards/${props.cardId}/buy`, {
        userId: user.id
      });
      alert('Карточка успешно куплена!');
      setIsOwned(true);
      setLevel(1);
      setPrice(props.upgradeCosts[1]);
      setNextUpgradeCost(props.upgradeCosts[1]);
      setIncome(props.incomePerHour[0] || 0);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Произошла ошибка при покупке карточки';
      alert(errorMessage);
    }
  };

  const handleUpgrade = async () => {
    try {
      await axios.post(`https://app.bdsm.money:5000/cards/${props.cardId}/upgrade`, {
        userId: user.id
      });
      alert('Карточка успешно улучшена!');
      const newLevel = level + 1;
      setLevel(newLevel);
      setPrice(props.upgradeCosts[newLevel] || 'Макс. уровень');
      setNextUpgradeCost(props.upgradeCosts[newLevel] || 'Макс. уровень');
      setIncome(props.incomePerHour[newLevel - 1] || 0);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Произошла ошибка при улучшении карточки';
      alert(errorMessage);
    }
  };

  return (
    <div className='CardBlock'>
      <div className="image">
        <img src={props.image} alt="Card" style={{ filter: isOwned ? "none" : "blur(3px)" }} />
      </div>

      <div className="price">
        {isOwned
          ? (level < 10
            ? `${getFormattedPrice(price)}`
            : 'Макс. уровень')
          : `${getFormattedPrice(props.price)}`}
        <div className="icon"><BsCoin /></div>
      </div>

      <div className="button">
        {isOwned ? (
          <>
            <a href="#" onClick={() => setShowInfoModal(true)}>Подробнее</a> {/* Кнопка для открытия модального окна */}
          </>
        ) : (
          <a href="#" onClick={handleBuy}>Купить</a>
        )}
      </div>

      {/* Модальное окно с информацией о карте */}
      {showInfoModal && (
        <div className="modal">
          <div className="modal-content">
            <img src={props.image} alt="Card" className="card-image" />
            <h2>{props.title}</h2>
            <p>{props.description}</p>
            <p>Уровень: {level}</p>
            <p>Прибыль: {income} <BsCoin />/ч</p> {/* Добавлено отображение прибыли */}
            {level < 10 && (
              <p>Стоимость улучшения: {getFormattedPrice(nextUpgradeCost)} <BsCoin /></p>
            )}
            {level < 10 && (
              <button onClick={handleUpgrade}>Улучшить</button>
            )}
            <button onClick={() => setShowInfoModal(false)}>Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardBlock;
