// Profile.jsx
import React from 'react';
import ProfileBlock from '../components/Profile/ProfileBlock';

function Profile() {
    return (
        <div className='Profile'>
            <div className='Profile__container'>
                <ProfileBlock />
            </div>
        </div>
    );
}

export default Profile;
