import React from 'react'
import HomeHeader from '../components/Home/HomeHeader'
import TaskBlock from '../components/Tasks/TaskBlock'

function Tasks() {
  return (
    <div className='Tasks'>
        <HomeHeader />

        <div className="tasks__container">
            <div className="title">
                Задания
            </div>

            <div className="text">
                Выполняйте задания и получайте баллы которые обменивайте на $BDSM
            </div>

            <TaskBlock
                type="telegram"
                title="Подписаться на tg канал"
            />
        </div>
    </div>
  )
}

export default Tasks