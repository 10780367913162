import React, { useState, useEffect } from 'react';
import { BsCoin, BsArrowRight, BsCheck2, BsLightningChargeFill } from "react-icons/bs";
import WebApp from '@twa-dev/sdk';
import axios from 'axios-esm';

function TapBlock() {
  const user = WebApp.initDataUnsafe?.user;

  const [balance, setBalance] = useState(0);
  const [passiveIncomePerHour, setPassiveIncomePerHour] = useState(0);
  const [energy, setEnergy] = useState(1500); // начальная энергия
  const [level, setLevel] = useState(6); // начальный уровень
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isTapped, setIsTapped] = useState(false);

  // Требования к уровню
  const levelRequirements = [0, 200, 1000, 10000];

  // Обновляем прогресс на основе текущего уровня и баланса
  useEffect(() => {
    const currentLevelIndex = level - 6; // Индекс текущего уровня
    const nextLevelRequirement = levelRequirements[currentLevelIndex + 1] || Infinity;

    // Если достигнут предел уровня, прогресс всегда будет 100%
    if (!nextLevelRequirement || balance >= nextLevelRequirement) {
      setProgressPercentage(100);
      return;
    }

    // Рассчитываем прогресс как процент от необходимого баланса для следующего уровня
    const progressToNextLevel = (balance / nextLevelRequirement) * 100;

    console.log("Баланс:", balance);
    console.log("Процент прогресса:", progressToNextLevel);

    setProgressPercentage(Math.min(100, Math.max(0, progressToNextLevel)));
  }, [balance, level]);

  // Загрузка данных пользователя
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const response = await axios.get(`https://app.bdsm.money:5000/user/${user.id}`);
        const userData = response.data;

        setBalance(userData.balance);
        setLevel(userData.level);
        setEnergy(Math.floor(userData.energy));

        // Массив доходов для каждого уровня карты
        const upgradeIncomeRates = [0, 500, 1000, 2500, 5000, 10000, 20000, 60000, 100000, 200000, 300000];

        // Суммируем доход от всех карт пользователя
        let totalPassiveIncomePerHour = userData.cards.reduce((total, card) => {
          const incomePerHour = upgradeIncomeRates[card.level] || 0;
          return total + incomePerHour;
        }, 0);

        setPassiveIncomePerHour(totalPassiveIncomePerHour);
      } catch (error) {
        console.error('Ошибка загрузки данных пользователя:', error);
      }
    };

    loadUserData();
  }, [user.id]);

  // Локальное увеличение энергии каждую секунду
  useEffect(() => {
    const localEnergyRegenInterval = setInterval(() => {
      setEnergy(prevEnergy => {
        if (prevEnergy < 1500) {
          return Math.min(1500, prevEnergy + 1.66); // Увеличиваем локально на 1.66
        }
        return prevEnergy;
      });
    }, 1000); // Обновление каждую секунду

    return () => clearInterval(localEnergyRegenInterval);
  }, []);

  // Синхронизация энергии с сервером каждые 5 секунд
  useEffect(() => {
    const serverSyncInterval = setInterval(async () => {
      try {
        const response = await axios.post(`https://app.bdsm.money:5000/user/${user.id}/regen-energy`);
        setEnergy(Math.floor(response.data.energy)); // Синхронизация с сервером
      } catch (error) {
        console.error('Ошибка при синхронизации энергии с сервером:', error);
      }
    }, 5000); // Вызов каждые 5 секунд

    return () => clearInterval(serverSyncInterval);
  }, [user.id]);

  // Обновление баланса каждые 5 секунд с учетом пассивного дохода
  useEffect(() => {
    const passiveIncomeInterval = setInterval(async () => {
      const passiveIncomeToAdd = (passiveIncomePerHour / 3600) * 5;
      const newBalance = balance + passiveIncomeToAdd;
      setBalance(newBalance);

      try {
        await axios.post(`https://app.bdsm.money:5000/user/${user.id}/update-balance`, {
          balance: newBalance
        });
      } catch (error) {
        console.error('Ошибка при обновлении баланса в БД:', error);
      }
    }, 5000);

    return () => clearInterval(passiveIncomeInterval);
  }, [user.id, passiveIncomePerHour, balance]);

  // Функция для увеличения баланса на 1 при нажатии на картинку
  const handleTap = async () => {

    if (energy < 2) {
      alert('Недостаточно энергии для клика');
      return;
    }

    try {
      const response = await axios.post(`https://app.bdsm.money:5000/user/${user.id}/click`);
      const { balance, energy, level } = response.data;

      setBalance(balance);
      setEnergy(Math.floor(energy));
      setLevel(level);

      setIsTapped(true);
      setTimeout(() => setIsTapped(false), 150);

    } catch (error) {
      console.error('Ошибка при клике:', error);
      alert('Недостаточно энергии для клика');
    }
  };

  return (
    <div className='TapBlock'>
      <div className="TapBlock__container">
        <div className="valueMoney">
          <div className="value">{Math.floor(balance)}</div>
          <div className="icon">$FAP</div>
        </div>
        <div className="valueFarm">
          {passiveIncomePerHour} <span>$FAP</span> 1h farm
        </div>

        <div className="levelRange">
          <div className="levelValue" style={{ width: `${progressPercentage}%` }}>
            {/* Отображаем текстовое значение прогресса для проверки */}
            <span style={{ color: "white", position: "absolute", top: "-20px" }}>{progressPercentage.toFixed(2)}%</span>
          </div>
        </div>


        <div className="textRange">
          <div className="block">
            <div className="icon"><BsCheck2 /></div>
            <div className="text">{level} см.</div>
          </div>
          <div className="block">
            <div className="text">{level + 1} см.</div>
            <div className="icon"><BsArrowRight /></div>
          </div>
        </div>
        <div className="tapBlock">
          <div
            className={`image ${isTapped ? 'tapped' : ''}`}
            onClick={handleTap}
          >
            <img
              src="https://i.postimg.cc/g2Vf9HY1/photo-2024-09-06-15-24-31.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="quanityTap">
          <div className="icon"><BsLightningChargeFill /></div>
          <div className="text">{Math.floor(energy)}/1500</div> {/* Округляем значение энергии вниз */}
        </div>
      </div>
    </div>
  );
}

export default TapBlock;
