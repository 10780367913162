import React, { useState, useEffect } from 'react';
import { BsCoin } from "react-icons/bs";
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

function CardsHeader() {
    const user = WebApp.initDataUnsafe?.user;
    const [balance, setBalance] = useState(0); // Состояние для баланса пользователя

    useEffect(() => {
        // Функция для загрузки данных пользователя
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://app.bdsm.money:5000/user/${user.id}`);
                const userData = response.data;
                setBalance(Math.floor(userData.balance));   // Устанавливаем баланс пользователя
            } catch (error) {
                console.error('Ошибка при загрузке данных пользователя:', error);
            }
        };

        // Загружаем данные пользователя, если user.id доступен
        if (user && user.id) {
            fetchUserData();
        }
        // Устанавливаем интервал для обновления данных каждую секунду
        const interval = setInterval(() => {
            if (user && user.id) {
                fetchUserData();
            }
        }, 1000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(interval);
    }, [user]);

    return (
        <div className='HomeHeader'>
            <div className="HomeHeader__container">
                <div className="left">
                    {user?.first_name} {user?.last_name} {/* Отображаем имя пользователя из WebApp */}
                </div>

                <div className="right">
                    <div className="right__block">
                        <div className="text">
                            {balance} {/* Отображаем баланс пользователя */}
                        </div>

                        <div className="icon">
                            <BsCoin />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardsHeader;
