import React from 'react'
import HomeHeader from '../components/Home/HomeHeader'
import TapBlock from '../components/Home/TapBlock'

function Home() {
  return (
    <div className='Home'>
        <HomeHeader />

        <TapBlock />
    </div>
  )
}

export default Home