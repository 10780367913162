import React from 'react'
import { BsTelegram, BsBrowserChrome } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

function TaskBlock(props) {
  return (
    <div className='TaskBlock'>
        <div className="left">
            <div className="icon">
                {props.type == "telegram" ? (
                    <BsTelegram />
                ) : (
                    <BsBrowserChrome />
                )}
            </div>

            <div className="title">
                {props.title}
            </div>
        </div>

        <div className="right">
            <div className="icon">
                <BsArrowRight />
            </div>
        </div>
    </div>
  )
}

export default TaskBlock