import React from 'react'
import { BsFillKeyFill } from "react-icons/bs";
import WebApp from '@twa-dev/sdk'

function HomeHeader() {
  const user = WebApp.initDataUnsafe?.user

  return (
    <div className='HomeHeader'>
        <div className="HomeHeader__container">
            <div className="left">
                {user.first_name} {user.last_name}
            </div>

            <div className="right">
                <div className="right__block">
                    <div className="text">
                        5
                    </div>

                    <div className="icon">
                        <BsFillKeyFill />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeHeader