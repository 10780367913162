import React from 'react';
import { BsHouse, BsCopy, BsPerson, BsCalendar2Check } from "react-icons/bs";
import { useLocation, Link } from 'react-router-dom';

function BottomNavigation() {
  const location = useLocation();

  return (
    <div className='BottomNavigation'>
      <div className="BottomNavigation__container">
        <div className={`item ${location.pathname === '/' ? 'active' : ''}`}>
          <Link to="/">
            <div className="icon">
              <BsHouse />
            </div>
            <div className="title">
              Главная
            </div>
          </Link>
        </div>

        <div className={`item ${location.pathname === '/cards' ? 'active' : ''}`}>
          <Link to="/cards">
            <div className="icon">
              <BsCopy />
            </div>
            <div className="title">
              Карты
            </div>
          </Link>
        </div>

        <div className={`item ${location.pathname === '/tasks' ? 'active' : ''}`}>
          <Link to="/tasks">
            <div className="icon">
              <BsCalendar2Check />
            </div>
            <div className="title">
              Задания
            </div>
          </Link>
        </div>

        <div className={`item ${location.pathname === '/profile' ? 'active' : ''}`}>
          <Link to="/profile">
            <div className="icon">
              <BsPerson />
            </div>
            <div className="title">
              Профиль
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BottomNavigation;
